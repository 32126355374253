.divGlobalResetPassword {
    width: 100%;
    height: 100vh;
  }

  .Connection-form-reset-pass {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card-reset-password{
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    overflow: visible !important;
    width: 45%;
  }

  .card-content-reset-password{
    display: flex;
    flex-direction: column;
  }

  .card-content-reset-password .reset-password-title{
    margin-top: 15px !important;
    margin-bottom: 12px !important;
    font-weight: 700;
    font-size: 18px;
    font-family: 'Roboto';
    letter-spacing: 0.15px;
  }
  
  .card-content-reset-password .reset-password-label-msg{
    font-size: 16px;
    line-height: 19px;
    margin-top: 15px;
    margin-bottom: 30px;
    font-family: 'Roboto';
    letter-spacing: 0.15px;
  }
 
  .card-content-reset-password .btn-reset-password {
    background: linear-gradient(180deg, #000000 0%, #000000 100%);
    color: white;
  }

  .card-content-reset-password .reset-password-sous-title{
    font-weight: 700;
    font-size: 16px;
    font-family: 'Roboto';
    letter-spacing: 0.15px;
    margin-top: 20px;
  }

  .reset-password-form{
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;
    margin-top: 12px;
  }

  .reset-password-form .btn-reset-form-password{
    background: linear-gradient(180deg, #000000 0%, #000000 100%);
    color: white;
    margin-top:20px;
  }

  .reset-password-form .reset-password-input-label {
    font-weight: 550;
    font-size: 14px;
    line-height: 12px;
    margin-top: 18px;
    margin-bottom: 9px !important;
    font-family: 'Roboto';
    letter-spacing: 0.15px;
  }

  .icon-reset-password {
    margin-right: 10px !important;
    height: 20px !important;
    width: 20px !important;
  }
  .icon-reset-visibility {
    height: 20px !important;
  }


  @media screen and (max-width: 970px) {
    .card-reset-password {
      width: 70%;
    }
  }

  @media screen and (max-height: 580px) {
    .card-content-reset-password .reset-password-title {
      margin-top: 10px !important;
      margin-bottom: 7px !important;
      font-size: 16px;
    }
    .reset-password-form .reset-password-input-label {
      font-size: 13px;
      margin-top: 14px;
      margin-bottom: 7px !important;
    }
    .icon-reset-visibility {
      height: 18.5px !important;
    }
    .reset-password-form .MuiOutlinedInput-root {
      height: 38px !important;
    }
  }