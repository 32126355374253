.clients {
  margin-top: 9.6vh;
  width: 100%;
  height: 86vh;
  overflow-y: hidden;
}

.label-client {
  background-color: #F8F8F8 !important;
  height: 50px;
  width: 100%;
  margin-bottom: 16px;
  margin-top: 22px;
  padding: 15px !important;
}

.datagrid-clients {
  height: 650px !important;
}

.delete-multiple .MuiSvgIcon-root {
  color: black !important;
}

.delete-multiple {
  background: white;
  border: 2px solid black !important;
  margin: -18px 0px 5px 0px !important;
  padding: 0px !important;
  height: 56px;
  width: 0px !important;
}
.db-btn {
  width: 150px;
  margin-top: 12px ;
  margin-right: 20px ;
  padding-right: 20px;
  padding-left: auto;
  height: 48px;
  background-color: black !important;
  border-radius: 5px !important;
}

@media screen and (max-height:920px) {
  .datagrid-clients {
    height: 580px !important
  }
}

@media screen and (max-height:847px) {
  .datagrid-clients {
    height: 510px !important
  }
}

@media screen and (max-height:765px) {
  .datagrid-clients {
    height: 450px !important
  }
}

@media screen and (max-height:696px) {
  .datagrid-clients {
    height: 400px !important
  }
}

@media screen and (max-height:635px) {
  .datagrid-clients {
    height: 335px !important
  }
}

@media screen and (max-height:539px) {
  .datagrid-clients {
    height: 300px !important
  }

  .clients {
    margin-top: 39px;
  }
}

@media screen and (max-height:498px) {
  .label-client .MuiInputLabel-root {
    font-size: 14px !important;
  }

  .label-client {
    margin-bottom: 10px;
    height: 42px;
  }

  .datagrid-clients {
    height: 282px !important;
  }

  .delete-multiple {
    margin: -8px 0px 4px 0px !important;
  }
}

@media screen and (max-height:440px) {
  .datagrid-clients {
    height: 239px !important;
  }
}

.dialog-title {
  font-weight: 600 !important;
  border-bottom: 1px solid #ccc;
}

.dialog-text {
  font-family: sans-serif !important;
  font-size: 18px !important;
  margin-top: 25px !important;
  color: rgb(0 0 0 / 95%) !important;
}

.dialog-btn {
  background: white !important;
  width: 70px !important;
  font-family: system-ui !important;
}

.no_btn {
  color: blue !important;
}

.yes-btn {
  color: red !important;
}

@media (max-width: 970px) {
  .modif-supp {
    display: none;
  }
}