.dialog-title-logout{
    font-weight: 600 !important;
    border-bottom: 1px solid #ccc;
}

.dialog-text{
        font-family: sans-serif !important;
        font-size: 18px !important;
        margin-top: 25px !important;
        color: rgb(0 0 0 / 95%) !important;
}

.dialog-btn-logout {
    background: white !important;
    width: 70px !important;
    font-family: system-ui !important;
  }
  
  .no-btn-logout {
    color: blue !important;
  }
  
  .yes-btn-logout {
    color: red !important;
    margin-left: 25px !important;
    margin-right: 20px !important;
  }