.tabs-container {
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
}

.tabs-container .MuiTabs-indicator {
    background-color: black !important;
}

.step1_fields {
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
    column-gap: 10px;
    border-bottom: 1px solid #E7EAEE;
    margin-top: 15px;
}

.step1_fields_key {
    color: #1976D2 !important;
    padding: 8px !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 22px !important;
    margin-left: 10px !important;
}

.step1_fields_value {
    padding: 8px !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
}

.MuiTabs-root .MuiTab-root.Mui-selected {
    color: black;
}

.MuiTab-root {
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    text-transform: none !important;
}

.confirm-label-container {
    background-color: #F8F8F8 !important;
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
    padding:10px;
    margin-top: 25px;
  }

  .confirm-champs{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 20px !important;
    width: 100% !important;
  }
  .champ{
    margin-bottom: 10px;
  }

  .texts{
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  .confirm-container{
    margin-top: 75px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    min-height: 140px;
  }
  .champ-value{
    font-weight: 400 !important;
    display: flex !important;
    flex-direction: row;
  }

  .confirm-champs-step2{
    padding-bottom: 10px !important;
    padding-left: 40px !important;
  }
  .champ-step2{
    font-family: Inter, Roboto, Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.01071em;
  }

  .texts-step2{
    margin-bottom: 15px;
    display: list-item;
  }

  .confirm-container-step2{
    margin-top: 30px;
    border: 1px solid #ccc;
    min-height: 140px
  }
  .champ-value-step2{
    font-weight: 400 !important;
  }

@media only screen and (max-width:1420px) {
    .step1_fields {
        grid-template-columns: 1fr 3fr;
    }

    .tabs-container {
        grid-template-columns: 1fr 3fr;
    }
}

@media only screen and (max-width:1195px) {
    .step1_fields {
        grid-template-columns: 1fr 2fr;
    }

    .tabs-container {
        grid-template-columns: 1fr 2fr;
    }
}

@media only screen and (max-width:1151px) {
    .step1_fields_value {
        font-size: 14px !important;
    }

    .step1_fields_key {
        font-size: 14px !important;
    }
}

@media only screen and (max-width:1050px) {
    .step1_fields {
        grid-template-columns: 1fr 3fr;
    }

    .tabs-container {
        grid-template-columns: 1fr 3fr;
    }

    .step1_fields_key {
        font-size: 12px !important;
        margin-left: 0px !important;
    }

    .step1_fields_value {
        font-size: 12px !important;
    }

    .MuiTab-root {
        font-size: 14px !important;
    }
}

@media only screen and (max-width:1050px) {
    .step1_fields {
        grid-template-columns: 1fr 2fr;
    }

    .tabs-container {
        grid-template-columns: 1fr 2fr;
    }
}

@media only screen and (max-width:955px) {
    .step1_fields {
        display: flex;
        flex-direction: row;
        column-gap: 0px;
    }

    .MuiTab-root {
        margin: 0px !important;
    }
}

@media only screen and (max-width:700px) {
    .MuiTab-root {
        font-size: 12.5px !important;
    }
}

@media only screen and (max-width:1150px) {
    .confirm-champs {
        grid-template-columns: repeat(2, 1fr); 
    }
   .texts{
      padding: 15px !important;
   }    
   .champ{
    font-size: 15px !important;
   }
}


