.sql-container {
  margin-top: 67px;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-container {
  margin: 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.sql-btn {
  width: 162px;
  margin-top: 12px !important;
  margin-left: '5px';
  height: 48px;
  background-color: black !important;
  border-radius: 5px !important;
}
.upload-btn {
  width: 162px;
  color: black;
  height : 48px;
  margin-top: 12px !important;
  border-radius: 5px !important;
}
.label-sql {
  background-color: #F8F8F8 !important;
  height: 50px;
  width: 95%;
  padding: 15px !important;
  margin-bottom: 30px;
}

.sql-card {
  width: 94.5%;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.sql-box {
  margin: 20px;
}

.select-box {
  margin: 20px;
}

.sql-label {
  margin-bottom: 7px;
}
.sql-textarea{
  overflow-x: hidden;
  overflow-y: hidden;
}
.sql-textarea textarea {
  font-family: "Roboto","Helvetica","Arial","sans-serif" !important;
  font-weight: 400 !important;
  font-size:17px !important;
  line-height: 1.5em !important;
  letter-spacing: 0.00938em !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

@media only screen and (max-width: 775px) {
  .sql-btn {
    width: 129px;
    margin-top: 0px !important;
    height: 40px;
  }
  .upload-btn{
    width: 150px;
    margin-top: 0px !important;
    height: 40px;
  }
}

@media only screen and (max-height: 660px) {
  .sql-btn {
    width: 129px;
    margin-top: 0px !important;
    height: 40px;
  }
  .upload-btn{
    width: 129px;
    margin-top: 0px !important;
    height: 40px;
  }
}

@media only screen and (max-height: 640px) {
  .label-sql {
    height: 36px;
    padding: 8px !important;
    margin-bottom: 10px;
  }

  .sql-cardContent> :last-child {
    padding-bottom: 0px !important;
  }

  .sql-cardContent {
    padding: 0px !important;
  }
}

@media only screen and (max-height: 570px) {
  .select-multiple {
    width: 100%;
    height: 38px;
  }
}

@media only screen and (max-height: 550px) {
  .sql-container {
    margin-top: 40px !important;
  }
}