   .card-config {
     max-height: 350px;
     overflow-y: auto !important;
     border: 1px solid #DEDEDE;
     padding: 0px 12px;
     width: 100%;
   }

   .New-field-label{
    margin-top: 5px;
    margin-bottom: 10px;
   }

   .grid-item .MuiFormControl-root .MuiInputBase-root {
     width: 100%;
     height: auto !important;
   }

   .card-label-container {
     background-color: #F8F8F8 !important;
     height: 40px;
     width: 100%;
     margin-bottom: 10px;
     margin-top: 10px;
   }

   .card-label-container>label {
     padding: 10px;
   }

   .card-label {
     font-size: 13px !important;
     grid-column: 1;
   }

   .add-container {
    display: flex;
    flex-direction: column;
     padding-bottom: 15px;
     border-bottom: 1px dashed #D1D1E0;
   }

   .add-container .MuiFormControl-root .MuiInputBase-root {
     width: auto !important;
     height: 51px;
   }

   .config-card {
     display: grid;
     grid-template-columns: 1fr 1fr;
     align-items: center;
     margin-bottom: 15px;
     border-bottom: 1px dashed #D1D1E0;
   }

   .config-card .MuiFormControl-root .MuiInputBase-root {
     width: auto;
     margin-top: 5px;
     margin-bottom: 12px; 
   }

   .long-mod-card {
     height: 548px !important;
   }

   .modules-title {
     color: #1976D2 !important;
     font-family: monospace !important;
     font-weight: 600 !important;
     margin: 12px 0px 0px 0px;
   }

   .input-delete {
     display: grid;
     grid-template-columns: 10fr 1fr;
     align-items: center;
   }

   .delete-icon {
     margin-left: 15px;
     margin-bottom: 10px;
     cursor: pointer;
   }

   @Media only screen and (max-width:852px) {
     .client_config {
       display: flex;
       flex-direction: column;
       align-items: baseline;
       margin-bottom: 17px;
       width: 100%;
       margin-top: 17px !important;
     }

     .client_config .TextInput {
       width: 20.5vw !important;
     }
   }

   .client_config_container {
     overflow-x: hidden;
   }

   @Media only screen and (max-width:599px) {
     .client_config_container {
       display: flex;
       flex-direction: column;
       justify-self: baseline;
     }

     .client_config .TextInput {
       width: 54.5vw !important;
     }
   }

   .butt-contain {
     margin-right: 15px;
     width: 35%;
   }

   .add_butt {
     background: #0A0A0B !important;
     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
     border-radius: 4px !important;
     margin-top: 0px !important;
     color: #fff !important;
     height: 42px;
     width: 131px !important;
     margin-left: auto;
    place-self: end;
   }

   .conf-cancel-btns {
     display: flex;
     flex-direction: row;
     height: 45px;
     width: 95px;
     grid-column: 1;
     margin-top: 10px;
     place-self: end;
     border: 1px solid #ccc;
     background-color: #f7f7f7;
     box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
     border-radius: 15px;
   }

   .switch-container {
     display: flex;
     align-items: center;
     flex-direction: row;
     border-bottom: 1px solid #D9D9D9;
     padding: 10px;
   }

   .chebox-container {
     display: flex;
     align-items: center;
     flex-direction: row;
   }

   .checkboxs {
     display: flex;
     align-items: center;
     flex-direction: row;
   }

   .card-module {
     border-radius: 4px;
     margin-top: 20px;
     border-top: 1px solid #D9D9D9;
     border-right: 1px solid #D9D9D9;
     border-bottom: 1px solid #D9D9D9;
     border-left: 1px solid #D9D9D9;
   }

   .MuiSwitch-switchBase.Mui-checked {
     color: #1976D2 !important;
   }

   .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
     background-color: #1976D2 !important;
   }

   .MuiCheckbox-root.Mui-checked {
     color: #1976D2 !important;
   }

   .MuiCheckbox-root.Mui-disabled {
     color: rgba(0, 0, 0, 0.38) !important;
   }

   .all-box {
     margin-right: 27px;
     margin-left: 20px;
   }

   .switchLabel {
     font-family: 'Roboto' !important;
     text-transform: uppercase;
   }

   .module-tabs .MuiTab-root.Mui-selected {
     color: #1976D2 !important;
   }

   .module-tabs .MuiTab-root {
     font-size: 15px !important;
     width: 8.5%;
   }

   @media screen and (max-width:1570px) {
     .module-tabs .MuiTab-root {
       font-size: 14px !important;
     }
   }

   .card-mod {
     height: 385px;
     overflow-y: auto !important;
     border: 1px solid #DEDEDE;
     padding: 0px 12px;
     width: 91.5%;
   }

   .chebox-container .MuiFormControlLabel-root {
     margin-left: 0px !important;
   }

   .InputText-card {
     display: grid !important;
   }

   .config-mod-card {
     display: flex;
     flex-direction: row;
     align-items: center;
     margin-bottom: 15px;
     border-bottom: 1px dashed #D1D1E0;
     padding-bottom: 10px;
     margin-bottom: 10px;
   }

   @Media only screen and (max-width:1397px) {
     .checkboxs .MuiFormControlLabel-label {
       font-size: 15px;
     }

     .module-tabs {
       width: 69vw;
     }
   }

   @Media only screen and (max-width:970px) {
     .module-tabs {
       width: 57vw;
     }

     .switchLabel {
       font-size: 15px !important;
     }
   }

   @Media only screen and (max-width:1060px) {
     .delete-icon {
       margin-bottom: 0px !important;
     }

     .long .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 28vw !important;
     }

     .checkboxs .MuiFormControlLabel-label {
       font-size: 14px;
     }

     .config-step .MuiFormControl-root .MuiInputBase-root {
       width: 57vw !important;
     }

     .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 39vw !important;
     }

     .config-card {
       display: flex !important;
       flex-direction: column !important;
       align-items: baseline !important;
       border-bottom: 1px dashed #D1D1E0;
       padding-bottom: 10px;
       margin-bottom: 10px;
     }

     .card-label {
       font-size: 12.5px !important;
     }

     .checkboxs {
       flex-direction: column !important;
       align-items: baseline !important;
     }

     .checkAll {
       margin-bottom: 12px;
     }
   }

   @Media only screen and (max-width:971px) {
     .client_config_container .MuiOutlinedInput-root {
       font-size: 13.5px !important;
     }

     .config-step .MuiFormControl-root .MuiInputBase-root {
       width: 55.5vw !important;
     }

     .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 35vw !important;
     }

     .long .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 24vw !important;
     }
   }

   @Media only screen and (max-width:920px) {
     .config-step .MuiFormControl-root .MuiInputBase-root {
       width: 53.5vw !important;
     }

     .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 29vw !important;
     }
   }

   @media only screen and (max-width:925px) {
     .long .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 21vw !important;
     }
   }

   @media only screen and (max-width:887px) {
     .long .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 19vw !important;
     }

     .checkboxs .MuiFormControlLabel-label {
       font-size: 13.5px;
     }

     .config-step .MuiFormControl-root .MuiInputBase-root {
       width: 51.5vw !important;
     }
   }

   @media only screen and (max-width:816px) {
     .long .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 15.6vw !important;
     }

     .client_config_container .MuiOutlinedInput-root {
       font-size: 13px !important;
     }

     .config-step .MuiFormControl-root .MuiInputBase-root {
       width: 48vw !important;
     }

     .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 25vw !important;
     }
   }

   @media only screen and (max-width:775px) {
     .delete-icon {
       margin-bottom: 0px !important;
     }

     .add_butt {
       height: 38px !important;
       width: 100px !important;
     }

     .card-label-container>label {
       font-size: 15px !important;
     }

     .card-label-container {
       height: 45px;
       margin-bottom: 10px;
     }

     .config-step .MuiFormControl-root .MuiInputBase-root {
       width: 79vw !important;
     }

     .long .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 42vw !important;
     }

     .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 54vw !important;
     }

     .checkboxs .MuiFormControlLabel-label {
       font-size: 13px;
     }

     .module-tabs {
       width: 90vw;
     }

     .switchLabel {
       font-size: 14px !important;
     }
   }

   @media only screen and (max-width:701px) {
     .long .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 40vw !important;
     }
   }

   @media only screen and (max-width:682px) {
     .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 48vw !important;
     }

     .long .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 38vw !important;
     }
   }

   @media only screen and (max-width:682px) {
     .long .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 32vw !important;
     }
   }

   @media only screen and (max-width:682px) {
     .add_butt {
       height: 32px !important;
       width: 84px !important;
     }

     .card-label-container>label {
       font-size: 14px !important;
     }

     .card-label-container {
       height: 39px;
     }
   }

   @media only screen and (max-width:599.95px) {
     .MuiTabs-root .MuiTabs-scrollButtons {
       display: flex !important;
     }

     .module-tabs {
       width: 83vw;
     }

     .config-step .MuiFormControl-root .MuiInputBase-root {
       width: 48vw !important;
     }

     .config-step .MuiFormControl-root .MuiInputBase-root {
       width: 71vw !important;
     }

     .long .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 27vw !important;
     }

     .module-step .MuiFormControl-root .MuiInputBase-root {
       width: 40vw !important;
     }

     .client_config_container .MuiOutlinedInput-root {
       font-size: 12.5px !important;
     }

     .modules-title {
       font-size: 15px !important;
     }
   }

   .dialog-title {
     font-weight: 600 !important;
     border-bottom: 1px solid #ccc;
   }

   .dialog-text {
     font-family: sans-serif !important;
     font-size: 18px !important;
     margin-top: 25px !important;
     color: rgb(0 0 0 / 95%) !important;
   }

   .dialog-btn {
     background: white !important;
     width: 70px !important;
     font-family: system-ui !important;
   }

   .no_btn {
     color: blue !important;
   }

   .yes-btn {
     color: red !important;
   }
   
   .clientName-config{
    display: flex;
    flex-direction: row;
    height: 43px;
    align-items: center;
    width: 100%;
    border-bottom: 1px dashed #D1D1E0;
    margin-bottom: 9px;
   }

   .clientName-label{
    margin-right: 8px !important;
   }