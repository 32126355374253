    .login-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      position: relative;
      /* background-color: RGB(240, 243, 247); */
    }

    .error {
      color: red;
      margin-top: 8px !important;
    }

    .image-container-login {
      margin-bottom: 40px;
    }

    .image-container-login img {
      width: 170px;
      height: 119px;
    }

    .formControl {
      display: flex;
      flex-direction: column;
    }

    .card-login {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      overflow: visible !important;
      width: 45%;
    }

    .card-login form {
      display: flex;
      flex-direction: column;
    }

    .card-login label {
      margin-bottom: 8px !important;
      font-family: 'Roboto';
      letter-spacing: 0.15px;
    }

    .connexion-btn {
      width: 100%;
      height: 53.15px;
      background: linear-gradient(180deg, #000000 0%, #000000 100%);
      margin-top: 24px !important;
    }


    .input_label {
      font-weight: 550;
      font-size: 14px;
      line-height: 12px;
      margin-top: 18px;
    }

    .bienvenue {
      height: 21.26px;
      font-weight: 700;
      font-size: 14px;
      margin-top: 20px;
    }

    .connexion {
      margin-top: 5px !important;
      margin-bottom: 12px !important;
      font-weight: 700;
      font-size: 24px;
    }

    .formControl-login {
      margin: 15px 0px !important
    }

    .icon-Login-Dashboard {
      margin-right: 10px !important;
      height: 20px !important;
      width: 20px !important;
    }

    .rememberMeDiv {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
      align-items: center !important;
      margin-top: 25px;
    }

    .rememberMeDiv label {
      margin-bottom: 0px !important;
      margin-right: 0px !important;
    }

    .rememberMeDivCol2 {
      cursor: pointer;
    }

    @media screen and (max-width: 970px) {
      .card-login {
        width: 70%;
      }
    }

    @media screen and (max-height: 815px) {
      .image-container-login {
        margin-bottom: 30px;
      }

      .image-container-login img {
        width: 142px;
        height: 105px;
      }

      .card-login {
        padding: 9px;
      }

      .connexion-btn {
        height: 40px;
        margin-top: 20px !important;
      }

      .rememberMeDiv {
        margin-top: 15px;
      }

      .formControl-login {
        margin: 5px 0px !important;
      }

    }

    @media screen and (max-height: 660px) {
      .image-container-login {
        margin-bottom: 20px;
      }

      .card-login {
        padding: 5px;
      }

      .bienvenue {
        height: 20px;
        margin-top: 12px;
      }

      .connexion {
        font-size: 21px
      }

      .formControl-login {
        margin: 0px !important;
      }

      .rememberMeDiv {
        margin-top: 9px;
      }

      .connexion-btn {
        height: 38px;
        margin-top: 15px !important;
      }

      .icon-Login-visibility {
        height: 20px !important;
      }

      .connexion-btn {
        height: 35px;
        margin-top: 12px !important;
      }
    }

    @media screen and (max-height: 590px) {
      .image-container-login {
        margin-top: 10px;
      }

      .image-container-login img {
        width: 130px;
        height: 95px;
      }

      .card-content-login {
        padding-top: 1px;
        padding-bottom: 1px;
      }

      .card-content-login {
        padding-top: 12px !important;
        padding-bottom: 22px !important;
      }

      .formControl-login .MuiTextField-root {
        height: 38px !important;
      }

      .formControl-login .MuiOutlinedInput-root {
        height: 38px !important;
      }

      .icon-Login-visibility {
        height: 18.5px !important;
      }
    }

    