.search-bar {
    margin-left: 11px !important;
    margin-top: 12px;
    width: 98%;
}

.search-bar .MuiPaper-root {
    opacity: 1 !important;
}

.stack-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.stack-container Button {
    margin-top: 0px !important;
}

.stack-container>*:last-child {
    grid-column: 3;
    justify-content: end;
    padding-right: 20px;
}

.stack-container-config Button {
    margin-top: 0px !important;
}

.reset {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 180px;
}

.rest-button {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
    margin: 24px 20px 0px 0px !important;
    width: 149px;
    height: 46px;
    background: rgb(46, 125, 50) !important;
    color: #fff !important;
}

.back {
    background: white;
    color: black !important;
    border-radius: 4px !important;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
    margin: 24px 20px 0px 0px !important;
    width: 149px;
    height: 46px;
    border: none !important;
}

.stack-container-config {
    display: flex;
    justify-self: end;
}

.next {
    background-color: black !important;
    color: #fff !important;
    border-radius: 4px !important;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
    padding: 5px 15px !important;
    border-radius: 4px !important;
    margin: 25px 0px 0px 20px !important;
    width: 149px;
    height: 46px;
    border: none !important;
}

.back:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.next:hover {
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.4);
}

.main {
    display: grid;
    row-gap: 30px;
    margin-top: 38px !important;
}

.success-step .MuiStepIcon-root {
    color: rgb(46, 125, 50) !important;
}

@media only screen and (max-width:662px) {
    .stack-container Button {
        width: 130px;
    }
}

@media only screen and (max-width:599.99px) {
    .stack-container Button {
        width: 44%;
    }
}

@Media only screen and (max-width:775px) {
    .next {
        width: 133px;
        height: 43px;
    }

    .back {
        width: 133px;
        height: 43px;
    }

    .first-line {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr !important;
        margin-left: 0px !important;
        width: 100% !important;
    }

    .first-line> :first-child {
        grid-column: 1;
        justify-self: start;
        margin-left: 8px;
    }

    .search-bar {
        margin-left: 3px !important;
        margin-top: 8px;
        width: 246px;
    }
}
