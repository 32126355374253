.drawer {
    height: 100vh;
    position: relative;
}

.image-container {
    display: flex;
    justify-content: center;
    margin-top:20px;
}

.image-container img {
    width: 170px;
    height: 119px !important;
}

.sideBar-list-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    width: 100%;
}

.sideBar-list-two{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 20px;
    width: 100%;
}

.list2 {
    align-self: self-end;
}

.lists-items {
    width: 90%;
    height: 53px;
}

@media screen and (max-height:595px) {
    .lists-items {
        height: 45px !important;
    }

    .sideBar-list-one {
        margin-top: 20px !important;
    }
}

@media screen and (max-height:481px) {
    .image-container {
        margin-top: 5px;
    }
}

@media screen and (max-height:440px) {
    .sideBar-list-two{
        position: initial;
        bottom: 0px !important;
    }
}