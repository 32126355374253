.my-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap: 2px;  
}

.grid-item {
  background-color: #fff;
  padding: 30px 20px 20px 0px;
  display: flex;
  flex-direction: column;
}

.grid-item Label {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14.22px !important;
  line-height: 19px !important;
  color: #727272 !important;
}

.grid-item input {
  padding: 8px;
  background: #F8F8F8;
  border: 1px solid #DDE2E8;
  border-radius: 8px;
  height: 35px !important;
}

@media only screen and (max-width:1221px) {
  .grid-item Label {
    font-size: 12.82px !important;
  }
}

@media only screen and (max-width:1133px) {
  .grid-item Label {
    font-size: 12.22px !important;
  }
}

@media only screen and (max-width:1097px) {
  .grid-item Label {
    font-size: 11.22px !important;
  }
}

@media only screen and (max-width:1036px) {
  .my-grid-container {
    display: flex !important;
    flex-direction: column !important;
  }

  .grid-item Label {
    font-size: 14.22px !important;
  }
}

@media only screen and (max-width:862px) {
  .grid-item Label {
    font-size: 14.22px !important;
  }
}

@media only screen and (max-width:660px) {
  .grid-item Label {
    font-size: 13.22px !important;
  }
}

@media only screen and (max-height:870px) {
  .grid-item input {
    height: auto !important;
  }
}

@media only screen and (max-height:990px) {
  .grid-item {
    padding: 30px 20px 8px 0px !important;
  }
}

@media only screen and (max-height:917px) {
  .grid-item {
    padding: 30px 20px 0px 0px !important;
  }
}