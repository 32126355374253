.divGlobalForgetPassword {
  height: 100vh;
  width: 100%;
  display: flex;
}

.Connection-form-forget-pass {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-forget-password {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  overflow: visible !important;
  width: 45%;
}


.card-content-forget-password .forget-password-form {
  display: flex;
  flex-direction: column;
}

.forget-password-form .reset-password-label {
  margin-top: 15px !important;
  margin-bottom: 12px !important;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
  font-family: 'Roboto';
  letter-spacing: 0.15px;
}

.formControl-forget-password {
  display: flex !important;
    flex-direction: column !important;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}


.formControl-forget-password .rest-email-label {
  font-weight: 550;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
  font-family: 'Roboto';
    letter-spacing: 0.15px;
}

.formControl-forget-password .champ-email{
margin-bottom: 20px;
}

.spn_chbx{
  margin-bottom: 18px !important;
  font-family: 'Roboto' !important;
    letter-spacing: 0.15px !important;
    color: black !important;
   text-decoration-color: black !important;
  }

 .btn-forget-password{
  background: linear-gradient(180deg, #000000 0%, #000000 100%);
}

.formControl-forget-password .error {
  color: red;
  margin-top: 8px !important;
}

.champ-email .icon-forgetPassword-inut{
    margin-right: 10px !important;
    height: 20px !important;
    width: 20px !important;
    color: black;
}

.card-content-recovery-password{
  display: flex;
  flex-direction: column;
}

.card-content-recovery-password .recovery-email-label{
  margin-top: 15px !important;
  margin-bottom: 12px !important;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
  font-family: 'Roboto';
  letter-spacing: 0.15px;
}

.card-content-recovery-password .recovery-check-email{
  font-size: 16px;
  line-height: 19px;
  margin-top: 15px;
  margin-bottom: 30px;
  font-family: 'Roboto';
  letter-spacing: 0.15px;
}

@media screen and (max-width: 970px) {
  .card-forget-password {
    width: 70%;
  }
}

@media screen and (max-width: 440px) {
   .btn-forget-password{
    font-size: 14px;
  }
  .forget-password-form .reset-password-label {
    font-size: 16px;
  }
  .formControl-forget-password .rest-email-label {
    font-size: 14px;
  }
}

@media screen and (max-width: 650px) {
  .card-content-recovery-password .recovery-email-label{
    font-size: 14px;
  }
  .card-content-recovery-password .recovery-check-email{
    font-size: 13px;
  }
}

@media screen and (max-height: 530px) {
  .forget-password-form .reset-password-label {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
    font-size: 17px;
}
.formControl-forget-password .rest-email-label {
  font-size: 14px;
  line-height: 16px;
}
.formControl-forget-password {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.spn_chbx {
  margin-bottom: 9px !important;}

}

@media screen and (max-height: 460px) {
  .forget-password-form .reset-password-label {
    margin-top: 0px !important;
  }
  .formControl-forget-password {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
 .champ-email .MuiOutlinedInput-root {
    height: 38px !important;
  }
}